html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto Slab', serif;
}

header {
    position: fixed;
    height: 100px;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
}

footer>div,
header>div,
section>div>div {
    max-width: 970px;
    margin: 0 auto;
    padding: 15px;
}

.headline {
    display: inline-block;
    margin-top: 15px;
}

.headline h1 {
    color: #4c4c4c;
    font-size: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
    font-weight: 700;
}

.headline h2 {
    color: #4c4c4c;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    margin: 0;
}

ul.navigation {
    margin: 0;
    padding: 0;
    display: inline-block;
    position: absolute;
    right: 0;
}

ul.navigation li {
    display: inline-block;
    padding: 20px;
    margin: 0;
    vertical-align: top;
}

a {
    color: #a7a7a7;
    text-decoration: none;
}

a:hover h1,
a:hover h2,
a:hover {
    color: #6995a9;
    text-decoration: none;
}

section {
    border-bottom: 1px solid #e6e6e6;
}

section:before {
    display: block;
    content: " ";
    margin-top: -100px;
    height: 100px;
    visibility: hidden;
}

section:nth-child(odd)>div {
    background-color: #f3f3f3;
    padding-bottom: 20px;
}

section h1 {
    text-align: center;
    font-size: 30px;
    line-height: 70px;
    text-transform: uppercase;
    padding: 20px;
    margin: 0;
    color: #5a5a5a;
}

section h1:after {
    border-bottom: 4px solid #5a5a5a;
    content: ' ';
    position: absolute;
    width: 180px;
    height: 70px;
    left: calc(50% - 90px);
}

section p {
    font-size: 16px;
    color: #5a5a5a;
}

section#headerImage>div {
    padding: 0;
}

section#headerImage>div>div {
    margin-top: 100px;
    max-height: 55%;
    padding: 20%;
    background-size: cover;
}

section#galleries>div {
    text-align: center;
}

section div.section_bigtop {
    margin-top: 100px;
    max-height: 55%;
    padding: 0 !important;
    margin: 0;
    max-width: 100%;
}

section div.section_bigtop>div {
    margin-top: 100px;
    max-height: 55%;
    background-size: cover;
    max-width: 100%;
    display: block;
    padding: 20%;
    position: relative;
    background-position: 50%;
}

section#index>div.section_bigtop>div {
    padding: 20%;
}

section#galleries .galleryItem {
    width: 260px;
    display: inline-block;
    padding: 10px 30px;
    overflow: hidden;
    cursor: pointer;
}

section#galleries .galleryItem div.photo {
    width: 100%;
    height: 170px;
    background-color: gray;
    background-size: cover;
}

section#galleries .galleryItem dl {
    position: relative;
    margin: 0;
    font-size: 14px;
    font-family: 'Roboto Slab', serif;
    font-weight: 300;
    text-align: left;
    display: flex;
}

section#galleries:not(.gallery_page) .galleryItem dd {
    white-space: nowrap;
}

section#galleries .galleryItem dt {
    display: inline-block;
    padding: 5px 0;
    height: 48px;
    flex-grow: 2;
}


/* section#galleries:not(.gallery_page) .galleryItem dt {
width: 70%;
}  */

section#galleries .galleryItem dd {
    padding: 5px 0;
    font-style: italic;
    text-align: right;
    flex-grow: 1;
    margin-inline-start: 0;
}

section#privacy {
    background-color: #f3f3f3;
    padding-bottom: 20px;
    margin-top: 100px;
}

footer {
    background-color: #4f4f4f;
    color: #6e6e6e;
    padding: 17px 17px 30px;
}

footer p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 26px;
}

footer a.right {
    float: right;
}

.pageNotFound {
    height: calc(80vh - 200px);
    margin: 110px 0 0;
    border-top: 1px solid;
    text-align: center;
}

.section_form {
    padding: 1rem;
}

#emailForm {
    max-width: 630px;
    margin: 0 auto 30px;
}

#emailForm textarea,
#emailForm input {
    border: 1px solid #d9d9d9;
    background-color: #f2f2f2;
    padding: 1.5em;
    width: 100%;
    font-style: italic;
    font-size: 0.8em;
    letter-spacing: 1px;
    font-weight: 300;
    color: #5f5f5f;
    font-family: Georgia;
    border-radius: 2px;
    outline: none;
    box-sizing: border-box;
}

#emailForm textarea:focus,
#emailForm input:focus {
    color: #6995a9;
    border: 1px solid #6995a9;
}

#emailForm input[type="button"] {
    background: #6e5166;
    color: #fff;
    margin-left: 105px;
    width: 100px;
}

#emailForm label.errorMsg {
    display: none;
}

#emailForm p.error label {
    color: #cc0000;
}

#emailForm p input.error,
#emailForm p.error input,
#emailForm p textarea.error,
#emailForm p.error textarea {
    color: #cc0000;
    border-color: #cc0000;
}

#emailForm p.error label.errorMsg {
    display: inline-block;
    color: #cc0000;
    width: 260px;
    margin-left: 10px;
}

#emailForm input#emailButton {
    cursor: pointer;
    background-color: #6995a9;
    color: #fff;
    border: 1px solid #6995a9;
}

#emailForm p.successMessage,
#emailForm p.errorMessage {
    display: none;
}

#emailForm.sendSuccess p.successMessage {
    display: inline;
}

#emailForm.sendError p.errorMessage {
    color: #cc0000;
    display: inline;
}

.react-cookie-banner {
    position: fixed;
    text-align: center;
    background-color: rgb(72, 72, 72);
    width: 100%;
    height: 45px;
    z-index: 10000;
    bottom: 0px;
}

.react-cookie-banner .cookie-message {
    line-height: 45px;
    font-weight: 500;
    color: rgb(240, 240, 240);
}

.react-cookie-banner .cookie-message a {
    color: rgb(240, 240, 240);
    text-decoration: underline;
    margin-left: 10px;
}

.react-cookie-banner .button-close {
    position: absolute;
    top: 50%;
    right: 35px;
    line-height: 24px;
    margin-top: -12px;
    padding: 0px 8px;
    background: green;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    font-size: 12px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    float: right;
}


/* styles={{
  banner: {
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    backgroundColor: 'rgb(72, 72, 72)',
    width: '100%',
    height: '45px',
    zIndex: 10000
  }, */


/* button:   {
    display: 'inline-block',
    background: 'green',
    borderRadius: '5px',
    float: 'right',
    color: '#fff'
  }
}}

<div class="react-cookie-banner" style="position: relative; text-align: center; background-color: rgb(72, 72, 72); width: 100%; height: 45px; z-index: 10000;">
<span class="cookie-message" style="line-height: 45px; font-weight: 500; color: rgb(240, 240, 240);">This website uses cookies to ensure you get the best experience on our website<a href="/privacy" style="color: rgb(240, 240, 240); text-decoration: underline; margin-left: 10px;">More info</a></span>
<button class="button-close" style="position: absolute; top: 50%; right: 35px; line-height: 24px; margin-top: -12px; padding: 0px 8px; background-color: rgba(255, 255, 255, 0.6); border: none; border-radius: 3px; box-shadow: none; font-size: 12px; font-weight: 500; color: rgb(36, 36, 36); cursor: pointer;">Got it!</button></div> */


/* 
section#galleries > div {
text-align: center;
}
section#galleries > div div.galleryList {
}
section#galleries > div div.galleryList .gallery {
width: 260px;
display: inline-block;
padding: 10px 30px;
}

section#galleries > div div.galleryList .gallery div.photo {
width: 100%;
height: 170px;
background-color: gray;
background-size: cover;
}

section#galleries > div div.galleryList .gallery dl {
position: relative;
margin: 0;
font-size: 14px;
font-family: 'Roboto Slab', serif;
font-weight: 300;
text-align: left;
}

section#galleries > div div.galleryList .gallery dt {
display: inline-block;
padding: 5px;
}

section#galleries > div div.galleryList .gallery dd {
position: absolute;
text-align: left;
right: 0;
top: 0;
padding: 5px 7px;
font-style: italic;
}



@media screen and (max-width: 740px) {

ul.navigation {
  position: relative;
  display: block;
}
.headline {
  padding-left: 20px
}
header {
  height: 140px;
}
section:before { 
  margin-top: -140px; 
  height: 140px; 
}
footer > div,
header > div,
section:not(#galleries) > div > div {
  padding-left: 35px;
  padding-right: 35px;
}
section#headerImage > div > div {
    margin-top: 140px;
}
}


/*@media screen and (max-width: 740px) {
section#galleries > div div.galleryList div.gallery {
width: 100%;
}
}*/


/*  */